import Container from '@paperstac/ui/lib/Container';
import Text from '@paperstac/ui/lib/Text';
import { ThemeContext } from '@paperstac/ui/lib/ThemeProvider';
import React, { memo, useContext } from 'react';

const Footer = memo((props) => {
  const { theme } = useContext(ThemeContext);

  return (
    <Text
      textAlign="center"
      bg={theme.colors.gray[3]}
      pt={4}
      pb={{
        mobile: 110,
        desktop: 4,
      }}
    >
      <Container>
        <Text fontSize={{ mobile: 14, desktop: 16 }}>
          Copyright &copy; {new Date().getFullYear()} Paperstac. All rights reserved.
        </Text>
      </Container>
    </Text>
  );
});

Footer.propTypes = {};

Footer.defaultProps = {};

Footer.displayName = 'Footer';

export default Footer;
