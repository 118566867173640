import AccountsProvider from '@paperstac/common/lib/components/AccountsProvider';
import IdentityProvider from '@paperstac/common/lib/components/IdentityProvider';
import * as env from '@paperstac/env';
import { init as firebaseInit } from '@paperstac/firebase/lib/firebaseClient';
import { getDomains } from '@paperstac/helpers/lib/domain';
import ThemeProvider from '@paperstac/ui/lib/ThemeProvider';
import React from 'react';
import Routes from './Routes';
import ConfigureIntercomMessenger from './components/ConfigureIntercomMessenger';
import TrpcProvider from './components/TrpcProvider';

firebaseInit(env);
const domain = getDomains().PARTNERS;

const App = React.memo((props) => {
  return (
    <ThemeProvider>
      <TrpcProvider>
        <IdentityProvider>
          <AccountsProvider domain={domain}>
            <>
              <ConfigureIntercomMessenger />
              <Routes />
            </>
          </AccountsProvider>
        </IdentityProvider>
      </TrpcProvider>
    </ThemeProvider>
  );
});

App.propTypes = {};

App.defaultProps = {};

App.displayName = 'App';

export default App;
