import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import { CREATE_ACCOUNT } from '@paperstac/common/lib/serverDispatchActionTypes';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import { BASE_PARTNERS_URL } from '@paperstac/env';
import { createAccountDispatchValidator } from '@paperstac/firestore-collections/lib/accounts';
import { getDomainFromBaseUrl } from '@paperstac/helpers/lib/domain';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import ErrorText from '@paperstac/ui/lib/ErrorText';
import FileUploadGroup from '@paperstac/ui/lib/form/FileUploadGroup';
import InputFormGroup from '@paperstac/ui/lib/form/InputFormGroup';
import ExternalLinkIcon from '@paperstac/ui/lib/icons/ExternalLinkIcon';
import { Form, Formik } from 'formik';
import get from 'lodash/get';
import React from 'react';

const accept = 'image/png, image/jpg, image/jpeg, image/gif';

const CreateOrgForm = React.memo(({ initialValues, onSuccess }) => {
  const { uid } = React.useContext(IdentityContext);

  return (
    <Formik
      initialValues={{
        displayName: '',
        avatar: '',
        isBroker: false,
        ...initialValues,
        domain: getDomainFromBaseUrl(BASE_PARTNERS_URL),
      }}
      validationSchema={createAccountDispatchValidator()}
      onSubmit={async (payload, { setSubmitting, setStatus }) => {
        payload.isBroker = typeof payload.isBroker === 'string' ? payload.isBroker === 'true' : payload.isBroker;
        setSubmitting(true);
        serverDispatch({ action: CREATE_ACCOUNT, payload })
          .then(() => !!onSuccess && onSuccess())
          .catch((error) => {
            setSubmitting(false);
            setStatus({ errorMessage: error.message });
          });
      }}
      children={(formik) => (
        <Form>
          <Box maxWidth={320}>
            <InputFormGroup name="displayName" label="Org Name:" required />
            <FileUploadGroup
              name="avatar"
              label="Logo/Icon:"
              uploadPath={`user-uploads/${uid}`}
              uploadButtonProps={{ variant: 'secondaryOutline', size: 'small', accept, children: 'Upload Image' }}
              replaceButtonProps={{ variant: 'secondaryOutline', size: 'small', accept, children: 'Replace Image' }}
              viewButtonProps={{
                variant: 'outline',
                size: 'small',
                children: (
                  <>
                    Preview{' '}
                    <ExternalLinkIcon
                      style={{
                        position: 'relative',
                        top: '-2px',
                      }}
                    />
                  </>
                ),
              }}
              mb={5}
            />
            <ErrorText children={get(formik, 'status.errorMessage')} mb={3} />
            <Button type="submit" variant="primary" block={true} busy={formik.isSubmitting}>
              Create Organization
            </Button>
          </Box>
        </Form>
      )}
    />
  );
});

CreateOrgForm.propTypes = {};

CreateOrgForm.defaultProps = {};

CreateOrgForm.displayName = 'CreateOrgForm';

export default CreateOrgForm;
