import { AccountsContext } from '@paperstac/common/lib/components/AccountsProvider';
import Avatar from '@paperstac/ui/lib/Avatar';
import Box from '@paperstac/ui/lib/Box';
import Drawer from '@paperstac/ui/lib/Drawer';
import DrawerHeader from '@paperstac/ui/lib/DrawerHeader';
import Flex from '@paperstac/ui/lib/Flex';
import ChevronRightIcon from '@paperstac/ui/lib/icons/ChevronRightIcon';
import ListGroup from '@paperstac/ui/lib/ListGroup';
import ListGroupItem from '@paperstac/ui/lib/ListGroupItem';
import Overlay from '@paperstac/ui/lib/Overlay';
import React, { memo } from 'react';

const ChooseAccountOverlay = memo(({ onChoose, onClose }) => {
  const { accounts, currentAccountId, setCurrentAccountId } = React.useContext(AccountsContext);
  const handleChoose = React.useCallback(
    (accountId) => {
      setCurrentAccountId(accountId);
      if (onChoose) onChoose(accountId);
    },
    [onChoose, setCurrentAccountId]
  );

  return (
    <Overlay onClose={onClose}>
      <Drawer width={360}>
        <DrawerHeader text="Choose an Organization" onClose={onClose} />
        <Box sx={{ p: 4, overflowY: 'auto', flex: '1 1 auto' }}>
          <ListGroup>
            {accounts.map((account) => (
              <ListGroupItem
                key={account.id}
                as="button"
                onClick={() => handleChoose(account.id)}
                variant={currentAccountId === account.id ? 'active' : 'default'}
                py={3}
                px={3}
                style={{ outline: 'none' }}
              >
                <Flex alignItems="center">
                  <Box>
                    <Avatar src={account.avatar} alt={account.displayName} size={30} display="block" mr={2} />
                  </Box>
                  <Box flex={1} fontSize={1}>
                    {account.displayName}
                  </Box>
                  <Box>
                    <ChevronRightIcon />
                  </Box>
                </Flex>
              </ListGroupItem>
            ))}
          </ListGroup>
        </Box>
      </Drawer>
    </Overlay>
  );
});

ChooseAccountOverlay.propTypes = {};

ChooseAccountOverlay.defaultProps = {};

ChooseAccountOverlay.displayName = 'ChooseAccountOverlay';

export default ChooseAccountOverlay;
