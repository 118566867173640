import { AccountsContext } from '@paperstac/common/lib/components/AccountsProvider';
import { PAPERSTAC_SUPPORT_URL } from '@paperstac/env';
import useBoolean from '@paperstac/hooks/lib/useBoolean';
import { LOGOUT, MANAGE_IDENTITY, MANAGE_ORG, MY_FEED } from '@paperstac/routes/lib/partnersRoutes';
import Avatar from '@paperstac/ui/lib/Avatar';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import Dropdown from '@paperstac/ui/lib/Dropdown';
import DropdownContent from '@paperstac/ui/lib/DropdownContent';
import DropdownTrigger from '@paperstac/ui/lib/DropdownTrigger';
import ConciergeColorIcon from '@paperstac/ui/lib/icons/ConciergeColorIcon';
import FeedListLayoutIcon from '@paperstac/ui/lib/icons/FeedListLayoutIcon';
import LogoutIcon from '@paperstac/ui/lib/icons/LogoutIcon';
import SupportIcon from '@paperstac/ui/lib/icons/SupportIcon';
import UserIcon from '@paperstac/ui/lib/icons/UserIcon';
import UsersIcon from '@paperstac/ui/lib/icons/UsersIcon';
import Image from '@paperstac/ui/lib/Image';
import ListGroupMenu from '@paperstac/ui/lib/ListGroupMenu';
import Text from '@paperstac/ui/lib/Text';
import { themeGet } from '@styled-system/theme-get';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ChooseAccountOverlay from './common/ChooseAccountOverlay';
import { intercomShow } from '@paperstac/common/lib/services/intercomMessenger';

let _closeDropdown;

const handleCloseDropdown = () => _closeDropdown && _closeDropdown();

const AccountDropdown = memo((props) => {
  const { accounts, currentAccount } = React.useContext(AccountsContext);
  const [isChoosing, startChoose, endChoose] = useBoolean();

  const items = [
    {
      label: 'My Feed',
      as: Link,
      icon: <FeedListLayoutIcon />,
      to: MY_FEED,
    },
    {
      label: 'Manage Identity',
      as: Link,
      icon: <UserIcon />,
      to: MANAGE_IDENTITY,
    },
    {
      label: 'Manage Organization',
      as: Link,
      icon: <Image src={currentAccount.avatar} border="none" size={16} borderRadius="circle" />,
      to: MANAGE_ORG,
    },
  ];
  if (accounts.length > 1) {
    items.push({
      label: 'Switch Organizations',
      as: 'button',
      icon: <UsersIcon />,
      onClick: startChoose,
    });
  }
  items.push({
    label: 'Knowledge Base',
    as: 'a',
    icon: <SupportIcon />,
    href: PAPERSTAC_SUPPORT_URL,
    target: '_blank',
    rel: 'noopener nofollow',
  });
  items.push({
    label: 'Contact Support',
    as: 'button',
    icon: <ConciergeColorIcon />,
    onClick: () => {
      intercomShow();
      handleCloseDropdown();
    },
  });

  return (
    <>
      <Dropdown>
        <DropdownTrigger>
          {({ closeDropdown, toggleDropdown }) => {
            _closeDropdown = closeDropdown;
            return <Avatar src={currentAccount.avatar} display="block" onClick={toggleDropdown} />;
          }}
        </DropdownTrigger>
        <DropdownContent width={230} alignRight>
          <DropdownContentHeader bg="darkBlue" py={3} textAlign="center">
            <Image
              src={currentAccount.avatar}
              size={80}
              sx={{
                border: 'thickTransparentWhite',
                borderRadius: 'circle',
              }}
            />
            <Text color="white" fontSize={1}>
              {currentAccount.displayName}
            </Text>
          </DropdownContentHeader>
          <ListGroupMenu py={2} px={3} borderRadius={0} items={items} />
          <DropdownContentFooter p={3} bg="gray.1">
            <Button as={Link} variant="secondary" sx={{ display: 'block' }} to={LOGOUT}>
              Logout <LogoutIcon ml={2} />
            </Button>
          </DropdownContentFooter>
        </DropdownContent>
      </Dropdown>
      {isChoosing && <ChooseAccountOverlay onChoose={endChoose} onClose={endChoose} />}
    </>
  );
});

const DropdownContentHeader = styled(Text)`
  border-top-left-radius: ${themeGet('radii.corner')};
  border-top-right-radius: ${themeGet('radii.corner')};
  border: ${themeGet('borders.default')};
  border-bottom: ${themeGet('borders.none')};
`;

const DropdownContentFooter = styled(Box)`
  border-bottom-left-radius: ${themeGet('radii.corner')};
  border-bottom-right-radius: ${themeGet('radii.corner')};
  border: ${themeGet('borders.default')};
  border-top: ${themeGet('borders.none')};
`;

AccountDropdown.propTypes = {};

AccountDropdown.defaultProps = {};

AccountDropdown.displayName = 'AccountDropdown';

export default AccountDropdown;
