import Box from '@paperstac/ui/lib/Box';
import Flex from '@paperstac/ui/lib/Flex';
import React, { memo } from 'react';
import AccountCheck from '../common/AccountCheck';
import Footer from '../Footer';
import Header from '../Header';

const DefaultLayout = memo(({ children }) => (
  <AccountCheck>
    <Flex flexDirection="column" minHeight="100vh">
      <Header />
      <Box as="main" flex={1} width="100%">
        {children}
      </Box>
      <Footer />
    </Flex>
  </AccountCheck>
));

DefaultLayout.propTypes = {};

DefaultLayout.defaultProps = {};

DefaultLayout.displayName = 'DefaultLayout';

export default DefaultLayout;
