export * from './actionTokenRoutes';
export * from './authRoutes';
export var CREATE_ORG = '/create-org';
export var HOME = '/';
export var MANAGE_IDENTITY = '/manage-identity';
export var MANAGE_ORG = '/manage-org';
export var MY_FEED = '/my-feed';
export var MY_FEED_ITEM = "".concat(MY_FEED, "?itemId=:id");
export var SHIPPING_ADDRESSES = '/shipping-addresses';
export var TERMS = '/terms';
