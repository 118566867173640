import * as routes from '@paperstac/routes/lib/partnersRoutes';
import React, { lazy, memo, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import LoadingPage from './components/pages/LoadingPage';

const ActionPage = lazy(() => import('./components/pages/ActionPage/ActionPage'));
const AuthActionsPage = lazy(() => import('./components/pages/AuthActionsPage/AuthActionsPage'));
const CreateOrgPage = lazy(() => import('./components/pages/CreateOrgPage/CreateOrgPage'));
const ForgotPasswordPage = lazy(() => import('./components/pages/ForgotPasswordPage/ForgotPasswordPage'));
const LoginPage = lazy(() => import('./components/pages/LoginPage/LoginPage'));
const LogoutPage = lazy(() => import('./components/pages/LogoutPage/LogoutPage'));
const ManageOrgPage = lazy(() => import('./components/pages/ManageOrgPage/ManageOrgPage'));
const MyFeedPage = lazy(() => import('./components/pages/MyFeedPage/MyFeedPage'));
const RegisterPage = lazy(() => import('./components/pages/RegisterPage/RegisterPage'));
const ShippingAddressesPage = lazy(() => import('./components/pages/ShippingAddressesPage/ShippingAddressesPage'));
const TermsPage = lazy(() => import('./components/pages/TermsPage/TermsPage'));

const ScrollToTop = withRouter(
  React.memo(({ children, location }) => {
    useEffect(() => window.scrollTo(0, 0), [location]);
    return children;
  })
);

const Routes = memo(() => (
  <Router>
    <ScrollToTop>
      <Suspense fallback={<LoadingPage />}>
        <Switch>
          <Route exact path={routes.ACTION} component={ActionPage} />
          <Route exact path={routes.AUTH_ACTIONS} component={AuthActionsPage} />
          <Route exact path={routes.CREATE_ORG} component={CreateOrgPage} />
          <Route exact path={routes.FORGOT_PASSWORD} component={ForgotPasswordPage} />
          <Route exact path={routes.HOME} component={MyFeedPage} />
          <Route exact path={routes.LOGIN} component={LoginPage} />
          <Route exact path={routes.LOGOUT} component={LogoutPage} />
          <Route exact path={routes.MANAGE_ORG} component={ManageOrgPage} />
          <Route exact path={routes.MY_FEED} component={MyFeedPage} />
          <Route exact path={routes.REGISTER} component={RegisterPage} />
          <Route exact path={routes.SHIPPING_ADDRESSES} component={ShippingAddressesPage} />
          <Route exact path={routes.TERMS} component={TermsPage} />
        </Switch>
      </Suspense>
    </ScrollToTop>
  </Router>
));

export default Routes;
