import { AccountsContext } from '@paperstac/common/lib/components/AccountsProvider';
import { HOME } from '@paperstac/routes/lib/partnersRoutes';
import UiHeader from '@paperstac/ui/lib/Header';
import Link from '@paperstac/ui/lib/Link';
import React, { memo, useContext } from 'react';
import PaperstacPartnersLogo from '../PaperstacPartnersLogo';
import AccountDropdown from './AccountDropdown';

const Header = memo((props) => {
  const { currentAccountId } = useContext(AccountsContext);

  return (
    <UiHeader
      branding={() => (
        <Link to={HOME}>
          <PaperstacPartnersLogo />
        </Link>
      )}
    >
      {!!currentAccountId && <AccountDropdown />}
    </UiHeader>
  );
});

Header.propTypes = {};

Header.defaultProps = {};

Header.displayName = 'Header';

export default Header;
