import { AccountsContext } from '@paperstac/common/lib/components/AccountsProvider';
import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import useBoolean from '@paperstac/hooks/lib/useBoolean';
import { MANAGE_ORG } from '@paperstac/routes/lib/partnersRoutes';
import Container from '@paperstac/ui/lib/Container';
import Heading from '@paperstac/ui/lib/Heading';
import React, { memo, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import ChooseAccountOverlay from './ChooseAccountOverlay';
import CreateOrgForm from './CreateOrgForm';

const AccountCheck = memo(({ children }) => {
  const { uid } = React.useContext(IdentityContext);
  const { accounts, currentAccountId, setCurrentAccountId } = useContext(AccountsContext);
  const [created, setCreated] = useBoolean();

  React.useEffect(() => {
    if (Array.isArray(accounts) && accounts.length === 1 && !currentAccountId) {
      setCurrentAccountId(accounts[0].id);
    }
  }, [accounts, currentAccountId, setCurrentAccountId]);

  if (created && !currentAccountId) return null; // Initial account just created, waiting on effect hook
  if (created && !!currentAccountId) return <Redirect to={MANAGE_ORG} />; // Initial account just created, redirect to manage org
  if (!uid) return children; // Not logged in, disregard account check
  if (!Array.isArray(accounts)) return null; // Accounts have not loaded, wait
  if (!!currentAccountId) return children; // Passed account check
  if (accounts.length > 1)
    return (
      <>
        {children}
        <ChooseAccountOverlay />
      </>
    );

  return (
    <Container py={5}>
      <Heading as="h1" sx={{ mb: 5, fontSize: 4 }}>
        Create an Organization
      </Heading>
      <CreateOrgForm onSuccess={setCreated} />
    </Container>
  );
});

AccountCheck.propTypes = {};

AccountCheck.defaultProps = {};

AccountCheck.displayName = 'AccountCheck';

export default AccountCheck;
