import DelayRender from '@paperstac/ui/lib/DelayRender';
import Flex from '@paperstac/ui/lib/Flex';
import Spinner from '@paperstac/ui/lib/Spinner';
import React from 'react';
import DefaultLayout from '../layouts/DefaultLayout';

const LoadingPage = () => (
  <DefaultLayout>
    <Flex alignItems="center" justifyContent="center" flexDirection="row" py={7}>
      <DelayRender>
        <Spinner size="large" />
      </DelayRender>
    </Flex>
  </DefaultLayout>
);

export default LoadingPage;
